
import { ref } from "vue";
import {
  createTokenAccount,
  createAssociatedTokenAccount
} from "@/solana/token";
import { chosenCluster } from "@/solana/connection";
import * as SolanaErrorHandler from "@/solana/SolanaErrorHandler";
import SecretFormField from "@/components/util/SecretFormField.vue";
import CopyIcon from "@/components/util/CopyIcon.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import Heading from "@/components/util/Heading.vue";
import Toggle from "@/components/util/Toggle.vue";

export default {
  components: {
    SecretFormField,
    CopyIcon,
    PublicKeyFormField,
    Heading,
    Toggle
  },
  setup() {
    const payerSecret = ref("");
    const payerSignsExternally = ref(true);
    const tokenMintAddress = ref("");
    const accountOwnerAddress = ref("");
    const creatingAccount = ref(false);
    const accountLink = ref("");
    const createdAccountAddress = ref("");
    const errorMessage = ref("");
    const createAssociatedAccount = ref(false);

    const createAccount = async () => {
      accountLink.value = "";
      creatingAccount.value = true;
      createdAccountAddress.value = "";
      errorMessage.value = "";
      try {
        createdAccountAddress.value = createAssociatedAccount.value
          ? await createAssociatedTokenAccount(
              payerSecret.value,
              payerSignsExternally.value,
              tokenMintAddress.value,
              accountOwnerAddress.value
            )
          : await createTokenAccount(
              payerSecret.value,
              tokenMintAddress.value,
              accountOwnerAddress.value,
              payerSignsExternally.value
            );
        accountLink.value = `https://explorer.solana.com/address/${createdAccountAddress.value}?cluster=${chosenCluster.value}`;
      } catch (err) {
        errorMessage.value = SolanaErrorHandler.getErrorMessage(err);
      }

      creatingAccount.value = false;
    };

    return {
      payerSecret,
      tokenMintAddress,
      accountOwnerAddress,
      creatingAccount,
      createAccount,
      createdAccountAddress,
      accountLink,
      errorMessage,
      payerSignsExternally,
      createAssociatedAccount
    };
  }
};
